<template>
    <div class="buyCoin-wrap">
        <van-nav-bar
            left-arrow
            title="购买"
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="buyCoin-main-wrap">
            <van-field center v-model="buyAmountValue" autocomplete="off" :formatter="formatter" :placeholder="`输入购买数量（${store.state.publicConfig.title}）`" :readonly="sellerInfos.type==2">
                <template #left-icon>
                    <van-image :src="themeImgs.commonTransIcon" />
                </template>
            </van-field>
            <p class="buy-amount" v-if="sellerInfos.type==1">可购总数：{{sellerInfos.currency}} {{store.state.publicConfig.title}}</p>
            <p v-if="siteName === 'qtpay'" class="buy-amount">请按照订单金额支付</p>
            <p v-else class="buy-amount">最小购买数量：{{sellerInfos.min_amount}} {{store.state.publicConfig.title}}</p>
            <div class="buyer-info-wrap">
                <div class="item-box">
                    <span class="label">卖家</span>
                    <div class="right-box">
                        <van-image :src="sellerInfos.avatar" />
                        <span>{{sellerInfos.nick_name}}</span>
                    </div>
                </div>
                <div class="item-box">
                    <span class="label">出售数量</span>
                    <div class="right-box">
                        <span>{{sellerInfos.count_currency}}</span>
                    </div>
                </div>
                <div v-if="siteName !== 'qtpay'" class="item-box">
                    <span class="label">出售方式</span>
                    <div class="right-box">
                        <span>{{sellerInfos.type == 1 ? '可拆售' : '不可拆售'}}</span>
                    </div>
                </div>
                <div class="item-box">
                    <span class="label">收款方式</span>
                    <div class="right-box">
                        <van-image :src="themeImgs[`commonPaymentIcon${item1}`]" v-for="(item1,index1) in sellerInfos.pay_type" :key="index1" />
                    </div>
                </div>
            </div>
            <div class="payment-box" v-for="(item,index) in paymentList" :key="index">
                <van-image :src="themeImgs[`commonPaymentIcon${item.type}`]" />
                <span>{{item.type == 1 ? '支付宝' : item.type == 2 ? '微信' : '银行卡'}}</span>
                <span>{{item.card&&accountFilter(item.card) || '******'}}</span>
                <van-button class="pay-btn" :disabled="btnClock" block @click="handleDoBuy(item)">支付</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getPaymentList, doBuy } from '@/api/api'
import { Toast } from 'vant'
import { useStore } from 'vuex'

export default {
    setup () {
        const $route = useRoute()
        const $router = useRouter()
        const store = useStore()
        const siteName = ref(siteChannel)
        // // 获取卖家卖币信息
        // const handleGetSellerInfos = () => {
        //     getSellerInfo({id:$route.query.id}).then(res => {
        //         if(res.result == 200000) {
        //             sellerInfos.value = res.data.list
        //             if(res.data.list.type == 2) {
        //                 buyAmountValue.value = res.data.list.currency
        //             }
        //             handleGetPaymentList()
        //         }
        //     })
        // }
        // onMounted(() => {
        //     handleGetSellerInfos()
        // })

        // 点击导航栏左侧返回键
        const onClickLeft = () => {
            history.back()
        }
        // 购买数量
        const buyAmountValue = ref('')
        // 只能输入整数跟2位小数
        const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
        // 存储收付款列表数据
        const paymentList = ref()
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 200000) {
                    paymentList.value = res.data
                    let buyerPayArr = []
                    Object.values(res.data).forEach(item => {
                        sellerInfos.value.pay_type.map(Number).forEach(num => {
                            if(item.type == num) {
                                buyerPayArr.push(item)
                            }
                        })
                    })
                    paymentList.value = buyerPayArr
                }
            })
        }

      // 存储卖家信息
      const sellerInfos = ref({})

      watch( () => $route.query.sellerInfos, (val) => {
        if (val) {
          sellerInfos.value = JSON.parse(val)

          if(sellerInfos.value.type == 2) {
            buyAmountValue.value = sellerInfos.value.currency
          }

          handleGetPaymentList()
        }
      }, {
        immediate: true
      })

        // 点击支付，购买货币
        const handleDoBuy = (item) => {
            if (btnClock.value) return
            if(!buyAmountValue.value) {
                return Toast.fail('请输入购买数量')
            } else if(buyAmountValue.value < Number(sellerInfos.value.min_amount)) {
                return Toast.fail('购买数量不能小于最小数量')
            }
            btnClock.value = true
            let params = {
                id: sellerInfos.value.id,
                type: item.type,
                amount: buyAmountValue.value
            }
            doBuy(params).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    $router.push({
                        path: 'buyerOrderDetails',
                        query: {
                            order_sn: res.data.info
                        }
                    })
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        // 将手机号或邮箱中间部分替换为*
        const accountFilter = (account) => {
            if (account) {
                let start = account.slice(0,4);
                let end = account.slice(-4);
                return `${start}******${end}`;
            }
        }
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            sellerInfos,
            onClickLeft,
            buyAmountValue,
            paymentList,
            handleDoBuy,
            accountFilter,
            formatter,
            btnClock,
            themeImgs,
            store,
            siteName
        }
    }
}
</script>

<style lang="less" scoped>
.buyCoin-wrap {
    .buyCoin-main-wrap {
        min-height: calc(100vh - 46px);
        padding: 24px 16px 16px;
        background: var(--mainBgColor);
        :deep(.van-field) {
            height: 50px;
            border-radius: 8px;
            &::after {
                border-bottom: none;
            }
            .van-field__left-icon {
                display: flex;
                align-items: center;
                .van-image {
                    width: 32px;
                    height: 32px;
                }
            }
            .van-field__label {
                width: 38%;
                font-weight: 500;
                font-size: 26px;
                color: var(--textColor);
            }
        }
        .buy-amount {
            margin-top: 10px;
            font-weight: 500;
            color: var(--textColor);
        }
        .buyer-info-wrap {
            margin-top: 16px;
            .item-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                color: var(--textCommonColor);
                .label {
                    font-weight: 500;
                    font-size: 16px;
                }
                .right-box {
                    display: flex;
                    align-items: center;
                    .van-image {
                        width: 24px;
                        height: 24px;
                        margin-right: 12px;
                    }
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
        .payment-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            color: var(--textCommonColor);
            .van-image {
                width: 24px;
                height: 24px;
            }
            span {
                font-weight: 500;
                font-size: 16px;
            }
            .pay-btn {
                width: 62px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #fff;
                border-radius: 8px;
            }
        }
    }
}
</style>
